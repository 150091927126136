<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" class="mt-5">
    <v-row >
      <v-col cols="6">
        <v-autocomplete dense :loading="isLoadingPacientes" v-model="pacienteSelecionado" :items="resumoPacientes" item-value="id" item-text="nome" outlined clearable label="Pacientes" @change="updatePacienteData" 
          :disabled="resumoPacientes.length === 1 && (resumoPacientes[0].id === 'no-patients' || resumoPacientes[0].id === 'error')"
          :messages="resumoPacientes.length === 1 && resumoPacientes[0].id === 'no-patients' ? 'Não há pacientes com solicitações de entrega pendente' : ''"
          class="custom-messages"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Solicitação da Troca" disabled v-model="dataRequisicao" type="date"  ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Previsão da Troca" disabled v-model="dataPrevista" type="date"  ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-card-title class="blod_color">Itens Trocados:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col> 
        <v-data-table dense :loading="isLoadingPacientes" :headers="ItemPacientelHeaders" :items="filterPaciente" show-select item-value v-model="selectedPacienteItems" class="elevation-1" :items-per-page="-1" > </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-text-field dense outlined label="Data da Troca"  v-model="dataTrocaEfetivada" type="date" :rules="requiredField" ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-file-input v-model="selectedFile" counter show-size persistent-hint dense outlined @change="checkFileSize" ref="fileInput">
          <template v-slot:label>
            <span>Anexar documento <span style="color: red;">*</span></span>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-btn class="mr-4 elevation-0" text @click="atualizarDados" :loading="isLoadingDados"> <v-icon left>mdi-refresh</v-icon> Atualizar  </v-btn>
      <v-col class="text-end">
        <ConfirmButton  :onConfirm="cancelarEntrega" :loading="isLoadingaCancelar" color="red" :disabled="!isSaveEnabledCancelar" :style="{ opacity: isSaveEnabledCancelar ? 1 : 0.5 }" > Cancelar Entrega  </ConfirmButton>
        <ConfirmButton class="ml-5" :loading="loading"  color="success" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"> Confirmar Entrega</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "CriarEsterilizacao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingPacientes: false,
      isLoadingaCancelar: false,
      pacientes: [],
      pacientesEsterelizavel: [],
      itensParaCancelar: [],
      resumoPacientes: [],
      pacienteSelecionado: null,
      dataRequisicao: "",
      dataPrevista: "",
      dataTroca: "",
      dataTrocaEfetivada: "",
      selectedPacienteItems: [],
      itensPacienteDesmarcados: [], 
      filterPaciente: [], 
      isLoadingDados: false,
      selectedFile: null,
      requiredField: [e => (e ? true : 'Obrigatório')],
      ItemPacientelHeaders: [
        { text: "Id ", value: "idEsterelizavel" },
        { text: "Id Esterilizável Retirado", value: "idEsterelizavelReferencia" },
        { text: "Id Esterilizável Entregue", value: "idEsterelizavelEntregue" },
        { text: "Esterilizável", value: "esterilizavel" },

      ],

    });
  },
  components: { ConfirmButton },
  methods: {
   async getPacientes() {
    this.isLoadingPacientes = true;
    try {
        const response = await api("pacientes/esterilizaveis/");
        // 🔹 Filtrando os pacientes com materiais esterilizáveis
        this.pacientes = response.data.filter(item => 
            item.data_solicitacao_troca !== null &&
            item.data_implantacao !== null &&
            item.ativo === true &&
            item.esterilizavel.disponibilidade === 14 &&
            (!item.data_solicitacao_retirada || item.data_solicitacao_retirada === '') &&
            item.esterilizavel
        );

        // 🔹 Filtrando apenas os itens entregues (disponibilidade ID 13)
        this.pacientesEsterelizavel = response.data.filter(item => 
            item.ativo === true &&
            item.disponibilidade.id === 13 &&
            item.data_implantacao === null &&
            item.troca === true
        );



        if (this.pacientes.length === 0) {
            this.resumoPacientes = [{ id: 'no-patients' }];
        } else {
            const agrupamento = this.pacientes.reduce((acc, atual) => {
                const dataReq = atual.data_solicitacao_troca.split('T')[0];
                const chave = `${atual.paciente.id}-${dataReq}`;

                if (!acc[chave]) {
                    const data = new Date(atual.data_solicitacao_troca);
                    data.setDate(data.getDate() + 1);
                    const dataFormatada = data.toLocaleDateString('pt-BR');

                    acc[chave] = {
                        id: chave,
                        pacienteId: atual.paciente.id,
                        nome: `ID: ${atual.paciente.id} - Nome: ${atual.paciente.nome} - Req (${dataFormatada})`,
                        esterilizavel: [],
                        data_solicitacao_troca: atual.data_solicitacao_troca,
                        data_prevista_solicitacao_troca: atual.data_prevista_solicitacao_troca,
                        data_prevista_implantacao: atual.data_prevista_implantacao,
                    };
                }

                // 🔹 Preenchendo `idEntrega` corretamente usando o mapa de correspondência
                acc[chave].esterilizavel.push({
                    id: `${atual.esterilizavel.nome.id}_${acc[chave].esterilizavel.length}`,
                    // id: atual.esterilizavel?.nome?.id,
                    // nome: atual.esterilizavel?.nome?.nome,
                    idReferencia: atual.esterilizavel?.id,
                    idEntrega: atual.esterilizavelIdCorrespondente, 
                    IdEsterelizavel: atual.id,
                    esterilizavel: atual.esterilizavel.nome.nome, 
                    dataValidade: atual.esterilizavel.data_validade_esterilizacao,
                    quantidade: 1
                });

                return acc;
            }, {});

            this.resumoPacientes = Object.values(agrupamento).map(item => ({
                id: item.id,
                pacienteId: item.pacienteId,
                nome: item.nome,
                esterilizavel: item.esterilizavel,
                data_solicitacao_troca: item.data_solicitacao_troca,
                data_prevista_solicitacao_troca: item.data_prevista_solicitacao_troca,
                data_prevista_implantacao: item.data_prevista_implantacao
              })).sort((a, b) => new Date(a.data_solicitacao_troca) - new Date(b.data_solicitacao_troca));
            }

            // console.log("✅ resumoPacientes atualizado:", this.resumoPacientes);
    } catch (error) {
        this.$toast.error(`Erro ao carregar pacientes. ${error}`);
    } finally {
        this.isLoadingPacientes = false;
    }
},

   updatePacienteData() {
    this.selectedPacienteItems = [];
    this.selectedEsterilizaveis = [];
    this.filterPaciente = [];

    const paciente = this.resumoPacientes.find(p => p.id === this.pacienteSelecionado);

    if (paciente) {
        this.dataRequisicao = paciente.data_solicitacao_troca || "";
        this.dataPrevista = paciente.data_prevista_solicitacao_troca || "";
        this.IDPaciente = paciente.pacienteId;

        // Mudou de eesterilizavel para esterilizavel
        if (paciente.esterilizavel && Array.isArray(paciente.esterilizavel)) {
            this.filterPaciente = paciente.esterilizavel.map((item, index) => ({
                id: `${item.id}_${index}`,
                originalId: item.id,
                pacienteId: paciente.pacienteId,
                idEsterelizavelReferencia: item.idReferencia,
                idEsterelizavelEntregue: item.idEntrega,
                IdEsterelizavel: item.IdEsterelizavel,
                esterilizavel: item.esterilizavel, // nome do esterilizável
                quantidade: 1,
            }));

            this.selectedPacienteItems = [...this.filterPaciente];
        }
    } else {
        this.dataRequisicao = "";
        this.dataPrevista = "";
        this.filterPaciente = [];
    }

    // Para debug
    // console.log('Paciente selecionado:', paciente);
    // console.log('Filter Paciente:', this.filterPaciente);
},






    async atualizarDados() {
      this.isLoadingDados = true;
      try {
        this.selectedPacienteItems = [];
        this.pacienteSelecionado = null;
        this.dataRequisicao = "";
        this.dataPrevista = ''
        this.filterPaciente = [];
        this.selectedFile = null
        await Promise.all([
          this.getPacientes()
        ]);
      } catch (error) {
        this.$toast.error('Erro ao atualizar os dados');
        console.error(error);
      } finally {
        this.isLoadingDados = false;
      }
    },
    checkFileSize() {
      if (this.selectedFile && this.selectedFile.size > 2000000) { // maior que 2MB
        this.$toast.error("O tamanho do arquivo é maior do que o permitido (2MB).");
        this.selectedFile = null; // remova a referência do arquivo para que o usuário não possa fazer o upload
        this.$refs.fileInput.reset();
      }
    },
    async ItensRetiradosPacienteNoEsterelizavelCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.idEsterelizavelReferencia}/`, {
            id: item.idEsterelizavelReferencia,
            disponibilidade: 4,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInluidosPacienteNoEsterelizavelCANCELAR() {
      try {
         const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.idEsterelizavelEntregue}/`, {
            id: item.idEsterelizavelEntregue,
            disponibilidade: 6,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar esterilizáveis:', error);
        this.$toast.error('Erro ao atualizar esterilizáveis.');
      }
    },
    async ItensAlteradosNoPacienteCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/esterilizavel/${item.IdEsterelizavel}/`, {
            id: item.IdEsterelizavel,
            disponibilidade: 4
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInlucidosnoPacienteCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/esterilizavel-id/${item.idEsterelizavelEntregue}/`, {
            esterilizavel: item.idEsterelizavelEntregue,
            disponibilidade: 16,
            ativo: false,
            troca: null
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },


    async ItensRetiradosPacienteNoEsterelizavel() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.idEsterelizavelReferencia}/`, {
            id: item.idEsterelizavelReferencia,
            disponibilidade: 10,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInluidosPacienteNoEsterelizavel() {

      try {
         const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.idEsterelizavelEntregue}/`, {
            id: item.idEsterelizavelEntregue,
            disponibilidade: 1,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar esterilizáveis:', error);
        this.$toast.error('Erro ao atualizar esterilizáveis.');
      }
    }, 
    async ItensAlteradosNoPaciente() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/esterilizavel/${item.IdEsterelizavel}/`, {
            id: item.IdEsterelizavel,
            ativo: false,
            data_troca: this.dataTrocaEfetivada,
            troca: null,
            disponibilidade: 17

          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInlucidosnoPaciente() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/esterilizavel-id/${item.idEsterelizavelEntregue}/`, {
            esterilizavel: item.idEsterelizavelEntregue,
            data_implantacao: this.dataTrocaEfetivada,
            disponibilidade: 1,
            troca: null
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    
    async updateDocPaciente() {
        let formData = new FormData();
        formData.append("arquivo", this.selectedFile);
        formData.append("tipodoc", 40);
        formData.append("ativo", true);
        formData.append("paciente", this.pacienteSelecionado.split('-')[0]);
        formData.append("tipopasta", 3);
        const currentDate = new Date().toISOString().split('T')[0];
        formData.append("data_carregado", currentDate);

        try {
          await api.post("/pacientes/docs/", formData);
          this.$toast.success("Documento carregado com sucesso!");
          this.selectedFile = null;
        } catch (error) {
          this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
        }
    },

    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      await this.ItensRetiradosPacienteNoEsterelizavel();
      await this.ItensInluidosPacienteNoEsterelizavel();  
      await this.ItensAlteradosNoPaciente();
      await this.ItensInlucidosnoPaciente();
      await this.updateDocPaciente();
      this.$toast.success('Baixado com sucesso!');
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
      
    },



  async cancelarEntrega() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;
      this.isLoadingaCancelar = true
      // console.log(this.selectedPacienteItems)

      await this.ItensRetiradosPacienteNoEsterelizavelCANCELAR();
      await this.ItensInluidosPacienteNoEsterelizavelCANCELAR();  
      await this.ItensAlteradosNoPacienteCANCELAR();
      await this.ItensInlucidosnoPacienteCANCELAR();
      this.selectedFile = null
      this.$toast.success('Cancelada com sucesso!');
      this.isLoadingaCancelar = true
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
    }

  },
  computed: {
    isSaveEnabled() {
      return (
        this.selectedPacienteItems.length > 0  && 
        this.selectedFile !== null
      );
    },
     isSaveEnabledCancelar() {
      return (
        this.selectedPacienteItems.length > 0 
      );
    }
  },
  mounted() {
    this.getPacientes();
    const hoje = new Date();
    const dataFormatada = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate()).padStart(2, '0');
    this.dataTrocaEfetivada = dataFormatada
  },
};
</script>

<style scoped>
.custom-messages ::v-deep .v-messages__message {
    color: red !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
</style>