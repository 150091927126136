import { render, staticRenderFns } from "./Troca.vue?vue&type=template&id=4102a2ac&scoped=true"
import script from "./Troca.vue?vue&type=script&lang=js"
export * from "./Troca.vue?vue&type=script&lang=js"
import style0 from "./Troca.vue?vue&type=style&index=0&id=4102a2ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4102a2ac",
  null
  
)

export default component.exports